
import { defineComponent } from 'vue'
import OuterPasswordEdit from '@/components/organisms/dms/OuterPasswordEdit/OuterPasswordEdit.vue'

export default defineComponent({
  name: 'PasswordEdit',
  components: {
    OuterPasswordEdit,
  },
})
