
import { computed, defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppInput from '@/components/atoms/AppInput.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import TextAlert from '@/components/molecules/TextAlert.vue'
import zxcvbn from 'zxcvbn'
import { postDataCredential, putDataCredential } from '@/helpers/util/webApiUtil'

const BASE_URL = process.env.VUE_APP_DMS_API_BASE_URL

export default defineComponent({
  name: 'OuterPasswordEdit',
  components: {
    AppInput,
    AppButton,
    DssIcon,
    TextAlert,
  },
  props: {
  },
  setup () {
    const router = useRouter()
    const store = useStore()
    const loginUser = computed(() => store.getters.loginUser)
    const currentPassword = ref('')
    const newPassword = ref('')
    const checkPassword = ref('')
    const currentPasswordMessage = ref('')
    const newPasswordMessage = ref('')
    const checkPasswordMessage = ref('')
    const apiMsg = ref('')
    const zxcvbnScore = computed(() => zxcvbn(newPassword.value).score)
    const barColor = computed(() =>
      zxcvbnScore.value === 0 && !newPassword.value ? ''
        : zxcvbnScore.value === 0 ? '#f14668'
          : zxcvbnScore.value === 1 ? '#f14668'
            : zxcvbnScore.value === 2 ? '#f14668'
              : zxcvbnScore.value === 3 ? '#ffd970'
                : zxcvbnScore.value === 4 ? '#48c78e' : ''
    )
    const barWidth = computed(() =>
      zxcvbnScore.value === 0 && !newPassword.value ? '0%'
        : zxcvbnScore.value === 0 ? '20%'
          : zxcvbnScore.value === 1 ? '20%'
            : zxcvbnScore.value === 2 ? '20%'
              : zxcvbnScore.value === 3 ? '40%'
                : zxcvbnScore.value === 4 ? '60%' : ''
    )

    const currentPassCheck = () => {
      if (!currentPassword.value) {
        currentPasswordMessage.value = '現在のパスワードを入力してください。'
      } else {
        currentPasswordMessage.value = ''
      }
      if (newPassword.value) {
        newPasswordMessage.value = currentPassword.value === newPassword.value ? '現在のパスワードと違うパスワードを設定してください。' : ''
      }
    }
    const newPassCheck = () => {
      if (!newPassword.value) {
        newPasswordMessage.value = '新しいパスワードを入力してください。'
      } else if (!newPassword.value.match(/^[a-zA-Z-\d!"#$%&'()*+.,/:;<=>?@_`[\]{|}~\\ -]*$/)) {
        newPasswordMessage.value = 'パスワードは半角英数字及び半角記号で入力してください。'
      } else if (zxcvbnScore.value < 3) {
        newPasswordMessage.value = '推測されにくいパスワードを設定してください。'
      } else if (currentPassword.value) {
        newPasswordMessage.value = currentPassword.value === newPassword.value ? '現在のパスワードと違うパスワードを設定してください。' : ''
      } else {
        newPasswordMessage.value = ''
      }
      if (checkPassword.value) {
        checkPasswordMessage.value = newPassword.value !== checkPassword.value ? '新しいパスワードと新しいパスワード（確認）が違います。' : ''
      }
    }
    const checkPassCheck = () => {
      if (!checkPassword.value) {
        checkPasswordMessage.value = '新しいパスワード（確認）を入力してください。'
      } else if (newPassword.value !== checkPassword.value) {
        checkPasswordMessage.value = '新しいパスワードと新しいパスワード（確認）が違います。'
      } else {
        checkPasswordMessage.value = ''
      }
    }
    return {
      router,
      currentPassword,
      newPassword,
      checkPassword,
      currentPasswordMessage,
      newPasswordMessage,
      checkPasswordMessage,
      apiMsg,
      zxcvbnScore,
      barColor,
      barWidth,
      currentPassCheck,
      newPassCheck,
      checkPassCheck,

      async update () {
        const url = `${BASE_URL}/outer/account/password/check`
        const body = JSON.stringify({
          userId: loginUser.value.id,
          password: currentPassword.value,
        })
        const response = await postDataCredential(url, body, true)
        if (response.status === 200) {
          const url = `${BASE_URL}/outer/account/${loginUser.value.id}`
          const body = JSON.stringify({
            userId: loginUser.value.id,
            password: newPassword.value,
          })
          const response = await putDataCredential(url, body)
          if (response.status === 200) {
            await router.push({ name: 'OuterPasswordCompletePage', params: { edited: 'true' } })
          } else {
            apiMsg.value = 'パスワードの変更に失敗しました。'
          }
        } else {
          apiMsg.value = '現在のパスワードが正しくありません。'
        }
      },
    }
  },
})
